import React from "react"

import Header from "../Header"
import '../../App.css'
import Button from "../Button.js"

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

/**
 * Styling
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


/**
 * Creating view for Home page
 */
export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <header>
      <Header />
    </header>


    <h1 id="page-title">Who Are We?</h1>
    <Container>
      <Container style={{ justifyContent: 'center'}}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div style={{ padding: "10%", fontSize: "18px", textAlign:"center", fontFamily: 'Georgia' }}>
              Previously known as Computer Graphics club, we joined
              ACM SIGGRAPH, the Association of Computing Machinery‘s Special
              Interest Group on Computer Graphics and Interactive Techniques.
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <img src={require('../images/acmwords.png')} width="100%" />
            </div>
          </Grid>
          <Grid item item xs={12} sm={4}>
            <div style={{ padding: "10%", fontSize: "18px", textAlign:"center", fontFamily: 'Georgia' }}>
              Formed out of a need to represent students with an
              interest in computer graphics, the club aims to be an asset to student life by
              connecting the San José State University community to the CG industry.
            </div>
          </Grid>
        </Grid>
      </Container>


      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div class="home-desc"></div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h2>CG is interdisciplinary</h2>
          <p style={{ paddingLeft: "10%", paddingRight: "10%", fontFamily: 'Georgia' }}>
          We want to take down barriers - between the art &
          science, and the design & engineering departments. We mean films, research, creative software, animation
          pipelines, projects that go beyond the courses and get people involved and working together. <strong>Computer graphics
          isn’t just tech, and it’s not just art – it’s both.</strong> And we want to demonstrate that.
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h2>Benefits of joining</h2>
          <p style={{ paddingLeft: "10%", paddingRight: "10%", fontFamily: 'Georgia' }}>
           We aim to go outside the campus,
           at <strong>connecting our club members to companies </strong>
           near and far us – getting students to those companies, and to the <strong>computer graphics
           conferences and events that are hosted around the year, like SIGGRAPH.</strong> We want to
           send people out to see what’s the latest in the industry, what new tools are out there,
           to learn and get connected with it all.</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h2>What you can learn</h2>
          <p style={{ paddingLeft: "10%", paddingRight: "10%", fontFamily: 'Georgia'}}>
          We want to bring the exciting world of CG to us,
          so that we can get caught up on the latest in <strong>virtual reality tech, or GPU acceleration, or physically
          based rendering.</strong> We want to not only make technology accessible to us here, but to connect us to that
          expanding industry - get hands-on kits and tech that we can work with for our classes and personal projects.
          </p>
        </Grid>
      </Grid>
    </Container>

    <Container maxWidth="sm" style={{ marginTop: "8%", marginBottom: "7%", padding:"2%", backgroundColor: "white", fontFamily: 'Georgia' }}>
      <h1>Keep in touch with us!</h1>
      <Button />
    </Container>
    
    </div>
  );
}
