import React from "react"

/**
 * Creates the title banner in the home page
 */
function Header(props)
{

  return (
    <div class="App-header">
      <h1 className="header-name">ACM SIGGRAPH <br />STUDENT CHAPTER AT SJSU</h1>
    </div>
  )
}

export default Header
