import React from 'react';

/**
 * Pictures from events
 */

// Pixar 2019
import brave from '../../images/eventpics/pixar2019/brave.JPG';
import coco from '../../images/eventpics/pixar2019/coco.JPG';
import group1 from '../../images/eventpics/pixar2019/group1.JPG';
import group2 from '../../images/eventpics/pixar2019/group2.JPG';
import group3 from '../../images/eventpics/pixar2019/group3.JPG';
import group4 from '../../images/eventpics/pixar2019/group4.JPG';
//import incredibles from '../../images/eventpics/pixar2019/incredibles.JPG';
import insideout from '../../images/eventpics/pixar2019/insideout.JPG';
import pixar from '../../images/eventpics/pixar2019/pixar.JPG';

// Boba Social 2019
import gongcha1 from '../../images/eventpics/boba2019/gongcha1.jpg';
import gongcha2 from '../../images/eventpics/boba2019/gongcha2.jpg';

// Maya Workshop #2 2019
import maya1 from '../../images/eventpics/maya2.2019/maya1.jpg';
import maya2 from '../../images/eventpics/maya2.2019/maya2.jpg';
import maya3 from '../../images/eventpics/maya2.2019/maya3.jpg';
import maya4 from '../../images/eventpics/maya2.2019/maya4.jpg';

// Pixar in a Box 2019
import pib1 from '../../images/eventpics/pixarInABox2019/PIB1.jpg';
import pib2 from '../../images/eventpics/pixarInABox2019/PIB2.jpg';
import pib3 from '../../images/eventpics/pixarInABox2019/PIB3.jpg';
import pib4 from '../../images/eventpics/pixarInABox2019/PIB4.jpg';
import pib5 from '../../images/eventpics/pixarInABox2019/PIB5.jpg';

// VR Demo
import vr1 from '../../images/eventpics/vr2019/vr1.jpg';
import vr2 from '../../images/eventpics/vr2019/vr2.jpg';
import vr3 from '../../images/eventpics/vr2019/vr3.jpg';
import vr4 from '../../images/eventpics/vr2019/vr4.jpg';
import vr5 from '../../images/eventpics/vr2019/vr5.jpg';
import vr6 from '../../images/eventpics/vr2019/vr6.jpg';


const vrEvent = [
  { img: vr1, },
  { img: vr2, },
  { img: vr3, },
  { img: vr4, },
  { img: vr5, },
  { img: vr6, },
]

const pixarInABoxEvent = [
  { img: pib1, },
  // { img: pib2, },
  { img: pib3, },
  { img: pib4, },
  { img: pib5, },
]

const maya2Event = [
  { img: maya1, },
  { img: maya2, },
  { img: maya3, },
  { img: maya4, },
]

const gongchaEvent = [
  { img: gongcha1, },
  { img: gongcha2, },
]

const pixarEvent = [
  { img: group1, },
  { img: insideout, },
  { img: group2, },
  { img: coco, },
  { img: group4, },
  // { img: group3, },
  { img: brave, },
  { img: pixar, },
]

const picData = [
  pixarEvent,
  gongchaEvent,
  maya2Event,
  pixarInABoxEvent,
  vrEvent,
];

export default picData;
