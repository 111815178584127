import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Link} from "react-router-dom"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


/**
 * Function to display and navigate the navbar
 */
export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //When changing tabs, the thing that indicates which is active
  //doesn't change

  const tabFont = {
    color: "white",
    fontFamily: 'Georgia'
  }

  return (
    <Paper class="nav" className={classes.root}>
        <Tabs
          centered
        >
          <img src={require('./images/acmnowords.png')} width="100px" height="80px"/>
          <Link to="/">
            <Tab id="text-nav" style={tabFont} label="Home" />
          </Link>
          <Link to="/events">
            <Tab id="text-nav" style={tabFont} label="Events" />
          </Link>
          <Link to="/about">
            <Tab id="text-nav" style={tabFont} label="About" />
          </Link>
        </Tabs>
    </Paper>
  );
}

