import React from "react"

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import SingleLineGridList from './eventcomponents/PastEvents'
// import PastEvents from './eventcomponents/PastEvents'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import picData from './eventcomponents/picData';

/**
 * Styling
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    maxWidth: 1200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'black',
    paddingBottom: 45,
    marginTop: 50,
    fontFamily: 'Georgia',
    backgroundColor: 'lightgray',
  },
  title1: {
    fontFamily: 'Georgia',
    fontSize: '40px',
    color: 'black',
    marginBottom: '7%',
  },
  eventContent: {
    padding: 40,
    color: 'white',
    paddingBottom: 0,
    marginBottom: 0,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
    marginBottom: '58px',
    fontSize: '50px',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  scroll: {
    textAlign: 'right',
  }
}))


/**
 * Other styling for the event cards
 */
const useStyles2 = makeStyles(theme => ({
  title: {
    textAlign: 'left',
  },
  desc: {
    marginTop: 30,
    marginBottom: 30,
  }
}))

/**
 * Creating the view for the events page
 */
export default function SimpleContainer() {
  const classes = useStyles();
  const style = useStyles2();

  return (
    <React.Fragment>
      <CssBaseline />
      <h1 id="page-title">EVENTS CALENDAR</h1>
      <Container maxWidth="sm">
        <Typography component="div" style={{ border: 10 }}>
          <p class="event-desc">Our events consist of workshops, panels, company visits, and more.
            We also share events hosted by other organizations that may interest our members in our
          <a href="https://discord.gg/EPGRKWw" target="_blank" rel="noopener noreferrer"> Discord.</a>
          </p>
        </Typography>
      </Container>
      <iframe src="https://calendar.google.com/calendar/embed?src=acmsiggraphsjsu%40gmail.com&ctz=America%2FLos_Angeles" style={{ border: 0,  marginBottom: '20%'}} id="calendar" height="600" frameborder="0" scrolling="no" title="calendar"></iframe>


      <h1 className={classes.title1}>PAST EVENTS</h1>

      <div className="past1">


        <div className={classes.eventContent}>
        <Grid container spacing={3}>



          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <Paper className={classes.paper} >

              <Container className={style.eventContent}>
                <h1>VR Demo</h1>
                <p>Nov 21, 2019</p>
                <p className={style.desc}>A selection of games in VR were made
                available for visitors to try!</p>

                <div className={classes.root}>
                  {picData[4].map(tile => (
                    <img src={tile.img} alt={tile.title} width='50%' height='50%' />
                  ))}
                </div>

              </Container>

            </Paper>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>




          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <Paper className={classes.paper} >

              <Container className={style.eventContent}>
                <h1>Pixar in a Box</h1>
                <p>Nov 14, 2019</p>
                <p className={style.desc}>Students from a wide range of
                majors came to learn more about what Pixar is looking for in
                the employees. They went through a crash course on the different
                technical roles at Pixar and explored lighting, storytelling,
                simulation, animation, character/environment modeling, and more!</p>

                <div className={classes.root}>
                  {picData[3].map(tile => (
                    <img src={tile.img} alt={tile.title} width='100%' height='100%'/>
                  ))}
                </div>

              </Container>

            </Paper>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>




          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <Paper className={classes.paper} >

              <Container className={style.eventContent}>
                <h1>Maya Workshop #2</h1>
                <p>Nov 12, 2019</p>
                <p className={style.desc}>The second workshop in the beginning
                Maya workshop series helped attendees build and improve on
                basic models. Resources for model and animation inspiration
                were also shared.</p>

                <div className={classes.root}>
                  {picData[2].map(tile => (
                    <img src={tile.img} alt={tile.title} width='100%' height='100%'/>
                  ))}
                </div>

              </Container>

            </Paper>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>




          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <Paper className={classes.paper}>

                <Container className={style.eventContent}>
                  <h1>Boba Social</h1>
                  <p>Nov 29, 2019</p>
                  <p className={style.desc}>ACM SIGGRAPH members came to mingle
                  with each other, destress, and meet new people at GongCha.</p>

                  <div className={classes.root}>
                    {picData[1].map(tile => (
                      <img src={tile.img} alt={tile.title} width='100%' height='100%'/>
                    ))}
                  </div>

                </Container>

            </Paper>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>



          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <Paper className={classes.paper} >

              <Container className={style.eventContent}>
                <h1>Pixar Visit</h1>
                <p>Oct 11, 2019</p>
                <p className={style.desc}>Selected students were able to visit Pixar's campus,
                meeting industry professionals and getting an inside look at the company.</p>

                <div className={classes.root}>
                  {picData[0].map(tile => (
                    <img src={tile.img} alt={tile.title} width='50%' height='50%'/>
                  ))}
                </div>

              </Container>

            </Paper>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}>
          </Grid>


        </Grid>
        </div>
      </div>


    </React.Fragment>
  );
}
