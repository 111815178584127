import React from "react"
import Icon from '@mdi/react'
import { mdiDiscord } from '@mdi/js';
import { mdiInstagram } from '@mdi/js';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


/**
 * Styling
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


/**
 * Creates the footer
 */
export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div>
    <Grid container spacing={0}>
      <img src={require('./images/yellowfooter.png')} style={{ display: 'block', marginTop: 150}} width="50%" height="100%"/>
      <img src={require('./images/yellowfooter.png')} style={{ display: 'block', marginTop: 150}} width="50%" height="100%"/>
    </Grid>
    <div class="footer">
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={0} sm={1} md={2} lg={2} xl={2}>
        </Grid>
        
        <Grid item xs>
          <h3>About</h3>
          <a href="https://www.siggraph.org/" target="_blank" rel="noopener noreferrer">
            <p>ACM SIGGRAPH</p>
          </a>
          <a href="https://www.acm.org/" target="_blank" rel="noopener noreferrer">
            <p>ACM</p>
          </a>
        </Grid>

        <Grid item xs>
          <h3>Membership</h3>
          <a href="https://forms.gle/gShgBkGLtfrdFPHT6" target="_blank" rel="noopener noreferrer">
            <p>Newsletter Signup</p>
          </a>
        </Grid>

        <Grid item xs>
          <h3>Socials</h3>
          <a href="https://discord.gg/EPGRKWw" target="_blank" rel="noopener noreferrer">
            <Icon path={mdiDiscord}
              title="Discord"
              size={1.5}
              horizontal
              vertical
              rotate={180}
              color="teal"
              /></a>
          <a href="https://www.instagram.com/acmsiggraphsjsu/" target="_blank" rel="noopener noreferrer">
            <Icon path={mdiInstagram}
              title="Discord"
              size={1.5}
              horizontal
              vertical
              rotate={180}
              color="teal"
              /></a>
        </Grid>
        <Grid item xs={0} sm={1} md={2} lg={2} xl={2}>
        </Grid>
      </Grid>
    </div>
    <br />
    <h2 style={{ fontFamily: 'Georgia', fontSize: '3vh'}}>ACM SIGGRAPH Student Chapter at SJSU</h2>
    <p style={{ fontSize: 12, fontFamily: 'Georgia', marginBottom: '5vh' }}>Last Updated Jan 2021</p>
    </div>
    </div>
  );
}
