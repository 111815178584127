import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


/**
 * Styling
 */
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
}));


/**
 * Creates the signup and facebook buttons 
 */ 
export default function ContainedButtons() {
  const classes = useStyles();

  const buttonStyle = {
    backgroundColor: '#F01666',
    color: "white",
  }

  return (
    <div class="button">
      <a href="https://discord.gg/EPGRKWw" target="_blank" rel="noopener noreferrer">
        <Button variant="contained" style={buttonStyle} className={classes.button}>
          Join our Discord
        </Button>
      </a>
      <a href="https://forms.gle/gShgBkGLtfrdFPHT6" target="_blank" rel="noopener noreferrer">
        <Button variant="contained" style={buttonStyle} className={classes.button}>
          Newsletter
        </Button>
      </a>
    </div>
  );
}
