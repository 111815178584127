import React from "react"

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';


/**
 * Styling
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontFamily: 'Georgia',
  },
}));


/** 
 * Creates view for About page
 */
export default function AutoGrid() {
  const classes = useStyles();

  const officerColor = {
    color: "black",
  }

  return (
    <div className={classes.root}>
      <h1 id="page-title" >ABOUT US</h1>
      <Container maxWidth="sm" style={{ marginBottom: "50px" }}>

        <Grid container spacing={3}>
            <Grid item xs>
              <p style={{ fontFamily: 'Georgia' }}>
                ACM SIGGRAPH Student Chapter at SJSU is a student organization at San José State
                University, in San Jose, CA. Formed out of a need to represent students with an
                interest in computer graphics, the club aims to be an asset to student life by
                connecting the SJSU community to the CG industry.
              </p>
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={3}>
              <h2 style={{color: "purple", fontFamily: 'Georgia' }}><strong>3 MAIN GOALS:</strong></h2>
            </Grid>
            <Grid item xs>
              <ol style={{ fontFamily: 'Georgia' }}>
                <li>Expanding awareness & presence of computer graphics technology on campus.</li>
                <br />
                <li>Uniting and sparking collaboration between the design, digital art, engineering, and science departments.</li>
                <br />
                <li>Connecting students to the companies, software, events, and technology of the computer graphics industry.</li>
              </ol>
            </Grid>
        </Grid>
      </Container>



      <h1 id="page-title">Officers 2020-2021</h1>
      <Container maxWidth="sm" id="officers">
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper} style={officerColor}>
              <h3>Yeab Kebede</h3>
              <p>President</p>
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper className={classes.paper} style={officerColor}>
            <h3>Lauren Chun</h3>
            <p>Vice-President</p>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper} style={officerColor}>
            <h3>Shana Nguyen</h3>
            <p>Treasurer</p>
            <br/>
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper className={classes.paper} style={officerColor}>
            <h3>Jasmin Younis</h3>
            <p>Marketing/Social Media Coordinator</p>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper} style={officerColor}>
            <h3>Kevin Smith</h3>
            <p>Club Advisor</p>
            </Paper>
          </Grid>
        </Grid>
      </Container>

    </div>
  );
}
