import React from 'react';
import './App.css';

import { BrowserRouter, Route, Switch } from "react-router-dom"

import MyInfo from "./components/MyInfo"
import Footer from "./components/Footer"
import CenteredTabs from "./components/NavBar"
import Top from "./components/Top"

import Home from "./components/pages/Home.js"
import Events from "./components/pages/Events.js"
import About from "./components/pages/About.js"
import Error from "./components/pages/Error.js"



class App extends React.Component {

  constructor()
  {
    super()
    this.state = 0
  }


  render () {
    return (
      <div className="App">
        <main>
        <BrowserRouter>
          <CenteredTabs />
          <Top />
          <Switch>
            <Route path="/" exact component={Home} currentPage = {true}/>
            <Route path="/events" component={Events} currentPage = {false}/>
            <Route path="/about" component={About} currentPage = {false}/>
            <Route component={Error} exact/>
          </Switch>
        </BrowserRouter>
        </main>
        <Footer />
      </div>
    )
  }
}


export default App
