import React from "react"

/**
 * Creates the page for a non-existent path
 */
const Error = () => {
  return (
    <div>
      <h1 style={{ paddingTop: 50, fontFamily: 'Georgia'}}>This page does not exist.</h1>
    </div>
  )
}

export default Error
